import React, { useState, useRef, useEffect } from "react";
// import { Editor } from "@tinymce/tinymce-react";
import { ChromePicker } from "react-color";
import Swal from "sweetalert2";
import parse from "html-react-parser";
import { useLang } from "../../helpers/language";
import config from "../../config";
import moment from "moment";
import EmojiPicker from "emoji-picker-react";
import {
	Input,
	PasswordInput,
	NumberInput,
	useMantineColorScheme,
	Card,
	Select,
	Radio,
	Group,
	Switch,
	Flex,
	Button,
	Checkbox,
	useMantineTheme,
	Stack,
	Text as MantineText,
	Center,
	Tooltip
} from "@mantine/core";
import dayjs from "dayjs";
import { DateInput } from "@mantine/dates";
import {
	IconAt,
	IconPhone,
	IconWorldWww,
	IconCalendar,
	IconBuildingBank,
	IconIdBadge2,
	IconSend,
	IconPaperclip,
	IconPlayerStop,
	IconTrash,
	IconSquareFilled,
	IconPlayerPlayFilled,
	IconPlayerStopFilled,
	IconMoodSmile,
	IconVideo,
	IconMicrophone,
	IconPhoneCall
} from "@tabler/icons-react";
import { IMaskInput } from "react-imask";

import { RichTextEditor } from "@mantine/tiptap";
import { Text } from "@tiptap/core";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import WaveSurfer from "wavesurfer.js";
import { T } from "../../helpers/translator";

let lastTheme = null;
let lightEditorTiming = (Math.random() * 100).toString() + 1;
let darkEditorTiming = (Math.random() * 100).toString();
let timeout = null;

const CustomInput = ({
	value,
	usageType,
	setter,
	apiurl,
	api,
	language,
	type,
	id,
	width,
	label,
	disabled,
	placeholder,
	optionListValue,
	radioValue,
	defaultValue,
	priceSetter,
	settimeInterval,
	searchable = false,
	clearable = true,
	trim,
	tolowercase,
	serverCall,
	trimStart,
	description,
	leftIcon,
	rightIcon,
	crud,
	user,
	textAreaProps = {},
	attachment = { files: [], setter: () => {} },
	audioRecorder = {
		audioFile: {},
		setAudioFile: () => {},
		uploadProgress: "",
		setUploadProgress: () => {},
		uploadSuccess: "",
		setUploadSuccess: () => {},
		audioURL: "",
		setAudioURL: () => {}
	},
	calling = {
		isCallStarted: null,
		startCall: null
	}
}) => {
	const textAreaDefaultProps = {
		toolbar: true,
		bold: true,
		italic: true,
		underline: true,
		strikethrough: true,
		code: false,
		alignLeft: false,
		alignCenter: false,
		alignRight: false,
		alignJustify: false,
		blockquote: false,
		h1: false,
		h2: false,
		h3: false,
		h4: false,
		hr: false,
		bulletList: false,
		orderedList: false,
		subscript: false,
		superscript: false,
		link: false,
		unlink: false,
		clearFormatting: false,
		highlight: false,
		undo: false,
		redo: false,
		submit: false,
		attachment: false,
		submitOnEnter: false,
		audioRecorder: false,
		emoji: false,
		videoCall: false,
		audioCall: false
	};
	const mergedTextAreaProps = { ...textAreaDefaultProps, ...textAreaProps };
	const [showColorPicker, setShowColorPicker] = useState(false);
	const { lang } = useLang();
	const { colorScheme } = useMantineColorScheme();
	const fileInputRef = useRef(null);
	const theme = useMantineTheme();
	const [openEmoji, setOpenEmoji] = useState(false);

	const handleChange = async (e) => {
		let value = e.target?.value;
		if (trim) {
			value = value.trim();
		}
		if (tolowercase) value = value.toLowerCase();
		if (trimStart) value = value.trimStart();
		if (serverCall && api && apiurl) {
			// Init a timeout variable to be used below
			clearTimeout(timeout);
			timeout = setTimeout(async function () {
				if (type === "email") {
					let res = await api.get(apiurl, {
						email: value
					});
					if (res[0]) {
						Swal.fire(config.swal.emailIsBusy[language]);
						setter("");
					}
				} else {
					let res = await api.get(apiurl, {
						username: value
					});
					if (res[0]) {
						Swal.fire(config.swal.usernameIsBusy[language]);
						setter("");
					}
				}
			}, 1000);
		}
		setter(value);
	};

	// const handlePriceChange = (e) => {
	// 	priceSetter(e?.target?.value);
	// };

	const editor = useEditor({
		extensions: [
			StarterKit,
			Underline,
			Superscript,
			SubScript,
			TextAlign.configure({ types: ["heading", "paragraph"] })
		],
		editable: !disabled,
		onUpdate({ editor }) {
			if (editor.getHTML() === "<p></p>") {
				editor.commands.setContent("");
				setter("");
			} else {
				setter(editor.getHTML());
			}
		},
		content: value
	});

	const clearEditor = () => {
		editor?.commands?.setContent("");
	};

	const handleTimeIntervalChange = (e) => {
		settimeInterval(e?.target?.value);
	};

	const handleTextFieldChange = (data) => {
		setter(data);
	};

	const handleColorChange = (color) => {
		setter(color.hex);
	};

	const toggleShowColorPicker = () => {
		setShowColorPicker(!showColorPicker);
	};

	// Function to handle file selection
	const handleFileChange = (event) => {
		const files = Array.from(event.target.files);
		if (files.length > 0) {
			// Handle file upload or display preview here
			attachment.setter([...attachment.files, ...files]);
		}
	};

	// Function to trigger the file input dialog
	const handleAttachFileClick = () => {
		fileInputRef?.current?.click();
	};

	// Web Audio Context
	const [isRecording, setIsRecording] = useState(false);
	const mediaRecorderRef = useRef(null);
	const audioChunksRef = useRef([]);
	const audioContextRef = useRef(null); // Web Audio Context
	const gainNodeRef = useRef(null); // Gain control
	const waveformRef = useRef(null); // Ref for the waveform container
	const wavesurferRef = useRef(null); // Ref to hold Wavesurfer instance
	const [recordingTime, setRecordingTime] = useState(0);
	const [totalDuration, setTotalDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const [isPlaying, setIsPlaying] = useState(false); // State to track playback status

	// Initialize AudioContext and GainNode only on user interaction
	const initializeAudioContext = () => {
		if (!audioContextRef.current) {
			audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
			gainNodeRef.current = audioContextRef.current.createGain(); // Create Gain Node
		}
	};

	// Start recording the audio
	const startRecording = async () => {
		try {
			initializeAudioContext();

			// Ensure the AudioContext is running
			if (audioContextRef.current.state === "suspended") {
				await audioContextRef.current.resume();
			}

			audioChunksRef.current = [];
			const stream = await navigator.mediaDevices.getUserMedia({
				audio: {
					sampleRate: 44100,
					channelCount: 1
				}
			});

			mediaRecorderRef.current = new MediaRecorder(stream);

			// Set up the audio pipeline
			const source = audioContextRef.current.createMediaStreamSource(stream);
			const filter = audioContextRef.current.createBiquadFilter();
			filter.type = "highpass";
			filter.frequency.value = 300;

			source.connect(filter);
			filter.connect(gainNodeRef.current);
			gainNodeRef.current.connect(audioContextRef.current.destination);

			mediaRecorderRef.current.ondataavailable = (event) => {
				audioChunksRef.current.push(event.data);
			};

			// Update recording time every second
			mediaRecorderRef.current.onstart = () => {
				setRecordingTime(0); // Reset recording time
				const interval = setInterval(() => {
					setRecordingTime((prev) => prev + 1); // Increment time by 1 second
				}, 1000);

				mediaRecorderRef.current.onstop = () => {
					const audioBlob = new Blob(audioChunksRef.current, { type: "audio/webm" });
					const audioFile = new File([audioBlob], `recording_${Date.now()}.webm`, {
						type: "audio/webm"
					});
					// Save the audio file (assuming audioRecorder is part of your app's context)
					audioRecorder.setAudioFile(audioFile);
					clearInterval(interval); // Stop the interval
				};
			};

			mediaRecorderRef.current.start();
			setIsRecording(true);
		} catch (error) {
			console.error("Error accessing microphone:", error);
			alert("Please allow microphone access to start recording.");
		}
	};

	// Stop the recording
	const stopRecording = () => {
		if (mediaRecorderRef.current) {
			mediaRecorderRef.current.stop();

			// Stop all media stream tracks
			const stream = mediaRecorderRef.current.stream;
			stream.getTracks().forEach((track) => track.stop());

			setIsRecording(false);
		}
	};

	// Handle play/pause button click
	const handlePlayPause = () => {
		if (isPlaying) {
			wavesurferRef.current.pause(); // Pause audio
		} else {
			wavesurferRef.current.play(); // Start playing audio
		}
		setIsPlaying(!isPlaying); // Toggle play/pause state
	};

	const formatTime = (timeInSeconds) => moment.utc(timeInSeconds * 1000).format("mm:ss");

	// Use Wavesurfer.js to visualize the audio waveform
	useEffect(() => {
		if (audioRecorder.audioFile && waveformRef.current) {
			// Create Wavesurfer instance
			wavesurferRef.current = WaveSurfer.create({
				container: waveformRef.current,
				waveColor: theme.colors.gray[6], // Set the color of the waveform using Mantine theme
				progressColor: theme.colors.cyan[9], // Set the color of the progress bar using Mantine theme
				height: 40, // Height of the waveform
				barWidth: 3, // Width of the bars in the waveform
				responsive: true, // Make it responsive
				cursorWidth: 3, // Thicker cursor line (default is 1)
				cursorColor: theme.colors.cyan[9] // Set the color of the cursor using Mantine theme
			});

			// Load the audio file into Wavesurfer
			const audioUrl = URL.createObjectURL(audioRecorder.audioFile);
			wavesurferRef.current.load(audioUrl);

			// Get total duration when the audio is ready
			wavesurferRef.current.on("ready", () => {
				const duration = wavesurferRef.current.getDuration(); // Total duration in seconds
				setTotalDuration(duration); // Save total duration to state
			});

			// Update recording time on playback progress
			wavesurferRef.current.on("audioprocess", () => {
				const currentTime = wavesurferRef.current.getCurrentTime();
				setCurrentTime(currentTime.toFixed(0)); // Update recording time in seconds
			});

			// Reset recording time when playback ends
			wavesurferRef.current.on("finish", () => {
				setIsPlaying(false);
				setCurrentTime(0);
			});

			// Cleanup when the component unmounts or the audio file changes
			return () => {
				wavesurferRef.current.destroy();
			};
		}
	}, [audioRecorder.audioFile]);

	useEffect(() => {
		if (mergedTextAreaProps.submitOnEnter) {
			const handleKeyDown = (e) => {
				if (e.key === "Enter" && value !== "") {
					mergedTextAreaProps.submit();
					editor?.commands?.setContent("");
				}
				setOpenEmoji(false);
			};

			document.addEventListener("keydown", handleKeyDown);

			return () => {
				document.removeEventListener("keydown", handleKeyDown);
			};
		}
	}, [mergedTextAreaProps.submitOnEnter, editor, mergedTextAreaProps.submit]);

	if ((crud && crud?.update) || user?.ananunaki) {
		if (usageType === "textfield") {
			return (
				<Input.Wrapper label={label}>
					<Input
						placeholder={placeholder}
						onChange={handleChange}
						value={value || ""}
						disabled={disabled}
						leftSection={leftIcon ? leftIcon : null}
						rightSection={rightIcon ? rightIcon : null}
					/>
				</Input.Wrapper>
			);
		} else if (usageType === "idCard") {
			return (
				<Input.Wrapper label={label}>
					<Input
						placeholder={placeholder}
						onChange={handleChange}
						value={value || ""}
						disabled={disabled}
						leftSection={<IconIdBadge2 size={16} />}
					/>
				</Input.Wrapper>
			);
		} else if (usageType === "bank") {
			return (
				<Input.Wrapper label={label}>
					<Input
						placeholder={placeholder}
						onChange={handleChange}
						value={value || ""}
						disabled={disabled}
						leftSection={<IconBuildingBank size={16} />}
					/>
				</Input.Wrapper>
			);
		} else if (usageType === "email") {
			return (
				<Input.Wrapper label={label}>
					<Input
						placeholder={placeholder}
						onChange={handleChange}
						value={value || ""}
						leftSection={<IconAt size={16} />}
						disabled={disabled}
					/>
				</Input.Wrapper>
			);
		} else if (usageType === "phone") {
			return (
				<Input.Wrapper label={label}>
					<Input
						placeholder={placeholder}
						onChange={handleChange}
						value={value || ""}
						leftSection={<IconPhone size={16} />}
						component={IMaskInput}
						mask="+374 (00) 00-00-00"
						disabled={disabled}
					/>
				</Input.Wrapper>
			);
		} else if (usageType === "password") {
			return (
				<Input.Wrapper label={label}>
					<PasswordInput
						placeholder={placeholder}
						id="your-password"
						value={value || ""}
						onChange={handleChange}
						disabled={disabled}
					/>
				</Input.Wrapper>
			);
		} else if (usageType === "textarea") {
			return (
				<RichTextEditor editor={editor}>
					{mergedTextAreaProps.toolbar && (
						<RichTextEditor.Toolbar sticky stickyOffset={60}>
							{[
								{
									group: [
										{ condition: mergedTextAreaProps.bold, component: <RichTextEditor.Bold /> },
										{ condition: mergedTextAreaProps.italic, component: <RichTextEditor.Italic /> },
										{
											condition: mergedTextAreaProps.underline,
											component: <RichTextEditor.Underline />
										},
										{
											condition: mergedTextAreaProps.strikethrough,
											component: <RichTextEditor.Strikethrough />
										},
										{
											condition: mergedTextAreaProps.clearFormatting,
											component: <RichTextEditor.ClearFormatting />
										},
										{ condition: mergedTextAreaProps.code, component: <RichTextEditor.Code /> }
									]
								},
								{
									group: [
										{ condition: mergedTextAreaProps.h1, component: <RichTextEditor.H1 /> },
										{ condition: mergedTextAreaProps.h2, component: <RichTextEditor.H2 /> },
										{ condition: mergedTextAreaProps.h3, component: <RichTextEditor.H3 /> },
										{ condition: mergedTextAreaProps.h4, component: <RichTextEditor.H4 /> },
										{ condition: mergedTextAreaProps.hr, component: <RichTextEditor.Hr /> }
									]
								},
								{
									group: [
										{
											condition: mergedTextAreaProps.blockquote,
											component: <RichTextEditor.Blockquote />
										},
										{
											condition: mergedTextAreaProps.bulletList,
											component: <RichTextEditor.BulletList />
										},
										{
											condition: mergedTextAreaProps.orderedList,
											component: <RichTextEditor.OrderedList />
										},
										{
											condition: mergedTextAreaProps.subscript,
											component: <RichTextEditor.Subscript />
										},
										{
											condition: mergedTextAreaProps.superscript,
											component: <RichTextEditor.Superscript />
										}
									]
								},
								{
									group: [
										{ condition: mergedTextAreaProps.link, component: <RichTextEditor.Link /> },
										{ condition: mergedTextAreaProps.unlink, component: <RichTextEditor.Unlink /> }
									]
								},
								{
									group: [
										{
											condition: mergedTextAreaProps.alignLeft,
											component: <RichTextEditor.AlignLeft />
										},
										{
											condition: mergedTextAreaProps.alignCenter,
											component: <RichTextEditor.AlignCenter />
										},
										{
											condition: mergedTextAreaProps.alignRight,
											component: <RichTextEditor.AlignRight />
										},
										{
											condition: mergedTextAreaProps.alignJustify,
											component: <RichTextEditor.AlignJustify />
										},
										{
											condition: mergedTextAreaProps.highlight,
											component: <RichTextEditor.Highlight />
										}
									]
								},
								{
									group: [
										{ condition: mergedTextAreaProps.undo, component: <RichTextEditor.Undo /> },
										{ condition: mergedTextAreaProps.redo, component: <RichTextEditor.Redo /> }
									]
								}
							].map((section, index) => (
								<RichTextEditor.ControlsGroup key={index}>
									{section.group.map(
										(item, idx) =>
											item.condition && <React.Fragment key={idx}>{item.component}</React.Fragment>
									)}
								</RichTextEditor.ControlsGroup>
							))}

							{/* Submit Button */}
							{mergedTextAreaProps.submit && (
								<RichTextEditor.ControlsGroup>
									<Tooltip label={T("send", lang)}>
										<Button
											variant="filled"
											aria-label="Send"
											onClick={() => {
												mergedTextAreaProps.submit();
												editor?.commands?.setContent("");
											}}
											disabled={!value || disabled}
										>
											<IconSend size={22} />
										</Button>
									</Tooltip>
								</RichTextEditor.ControlsGroup>
							)}

							{/* Attachment Button */}
							{mergedTextAreaProps.attachment && (
								<RichTextEditor.ControlsGroup>
									<Tooltip label={T("attachFile", lang)}>
										<Button onClick={handleAttachFileClick} variant="light">
											<IconPaperclip
												size={22}
												stroke={1.5}
												color={colorScheme !== "dark" ? "#212121" : "#ffffff"}
											/>
										</Button>
									</Tooltip>
								</RichTextEditor.ControlsGroup>
							)}

							{/* Audio Recorder */}
							{mergedTextAreaProps.audioRecorder && (
								<RichTextEditor.ControlsGroup>
									<Stack spacing="md" align="center">
										{!audioRecorder.audioFile ? (
											!isRecording ? (
												<Tooltip label={T("startRecording", lang)}>
													<Button onClick={startRecording} variant="light">
														<IconMicrophone size={18} />
													</Button>
												</Tooltip>
											) : (
												<Center inline>
													<Tooltip label={T("stopRecording", lang)}>
														<Button onClick={stopRecording}>
															<IconSquareFilled size={18} variant="light" />
														</Button>
													</Tooltip>

													<MantineText
														size="sm"
														c={theme.colors.cyan[9]}
														style={{
															fontWeight: "bold",
															fontSize: "0.8rem",
															position: "absolute",
															noUserSelect: "none",
															textShadow: "0 0 5px white",
															cursor: "pointer"
														}}
													>
														{recordingTime}
													</MantineText>
												</Center>
											)
										) : null}
										{audioRecorder.audioFile && (
											<Card
												shadow="xs"
												padding="xs"
												radius="md"
												h={60}
												style={{ width: "100%", maxWidth: "600px" }}
											>
												<Flex direction="row" align="center" h={"100%"} justify="center">
													<Center>
														<Tooltip
															label={
																wavesurferRef.current?.isPlaying()
																	? T("stop", lang)
																	: T("play", lang)
															}
														>
															<Button
																radius="sm"
																m={0}
																onClick={handlePlayPause}
																variant="light"
																mr={theme.spacing.sm}
															>
																{wavesurferRef.current?.isPlaying() ? (
																	<IconPlayerStopFilled size={18} />
																) : (
																	<IconPlayerPlayFilled size={18} />
																)}
															</Button>
														</Tooltip>
													</Center>
													<div
														id="waveform"
														ref={waveformRef}
														style={{
															width: "100%",
															maxWidth: "600px",
															minWidth: "100px",
															opacity: isPlaying ? 1 : 0,
															zIndex: 1
														}}
													></div>

													<MantineText
														style={{
															position: "absolute",
															bottom: theme.spacing.md,
															textAlign: "center",
															opacity: isPlaying ? 0 : 1
														}}
														align="center"
														size="md"
														mt={8}
													>
														{formatTime(totalDuration)}
													</MantineText>

													<Center>
														<Tooltip label={T("remove", lang)}>
															<Button
																ml={theme.spacing.sm}
																onClick={() => audioRecorder.setAudioFile(null)}
																color="gray"
															>
																<IconTrash size={18} />
															</Button>
														</Tooltip>
													</Center>
												</Flex>
											</Card>
										)}
									</Stack>
								</RichTextEditor.ControlsGroup>
							)}

							{/* Emoji Picker */}
							{mergedTextAreaProps.emoji && (
								<RichTextEditor.ControlsGroup>
									<Stack spacing="md" align="center" style={{ position: "relative" }}>
										<Tooltip label={T("pickEmoji", lang)}>
											<Button variant="light" onClick={() => setOpenEmoji(!openEmoji)}>
												<IconMoodSmile size={18} />
											</Button>
										</Tooltip>
										<EmojiPicker
											open={openEmoji}
											lazyLoadEmojis
											theme={colorScheme === "light" ? "light" : "dark"}
											onEmojiClick={(emojiObject) => {
												editor?.commands?.insertContent(emojiObject.emoji);
											}}
											style={{
												position: "absolute",
												bottom: "50px"
											}}
										/>
									</Stack>
								</RichTextEditor.ControlsGroup>
							)}

							{/* video Button */}
							{mergedTextAreaProps.videoCall && (
								<RichTextEditor.ControlsGroup>
									<Stack spacing="md" align="center" style={{ position: "relative" }}>
										<Tooltip label={T("startVideoCall", lang)}>
											<Button
												onClick={() => {
													calling.startCall("video");
												}}
												variant="light"
											>
												{!calling.isCallStarted ? (
													<IconVideo size={18} />
												) : (
													<IconPlayerStop size={18} />
												)}
											</Button>
										</Tooltip>
									</Stack>
								</RichTextEditor.ControlsGroup>
							)}

							{/* audio Button */}
							{mergedTextAreaProps.audioCall && (
								<RichTextEditor.ControlsGroup>
									<Stack spacing="md" align="center" style={{ position: "relative" }}>
										<Tooltip label={T("startCall", lang)}>
											<Button
												onClick={() => {
													calling.startCall("audio");
												}}
												variant="light"
											>
												{!calling.isCallStarted ? (
													<IconPhoneCall size={18} />
												) : (
													<IconPlayerStop size={18} />
												)}
											</Button>
										</Tooltip>
									</Stack>
								</RichTextEditor.ControlsGroup>
							)}
						</RichTextEditor.Toolbar>
					)}

					{/* Hidden file input to trigger the file selection */}
					<input
						type="file"
						ref={fileInputRef}
						onChange={handleFileChange}
						style={{ display: "none" }}
						multiple
					/>

					<RichTextEditor.Content />
				</RichTextEditor>
			);
		} else if (usageType === "textarea-tiny") {
			// let lightEdotor = (
			// 	<Input.Wrapper label={label}>
			// 		<Editor
			// 			id={lightEditorTiming}
			// 			key={lightEditorTiming}
			// 			disabled={disabled}
			// 			onInit={(evt, editor) => (editorRef.current = editor)}
			// 			init={{
			// 				height: "50vh",
			// 				width: "100%",
			// 				strict_loading_mode: true,
			// 				statusbar: false,
			// 				branding: false,
			// 				placeholder: placeholder,
			// 				fontsize_formats: "8px 10px 12px 14px 18px 24px 36px",
			// 				menubar: "file edit insert view format table",
			// 				plugins: [
			// 					"charmap advlist autolink lists link image charmap print preview anchor",
			// 					"searchreplace visualblocks code fullscreen",
			// 					"insertdatetime media table paste code help wordcount code"
			// 				],
			// 				formatpainter_blacklisted_formats:
			// 					"link,address,removeformat,formatpainter_removeformat",
			// 				toolbar:
			// 					// eslint-disable-next-line
			// 					"charmap | undo redo | formatselect | bold italic backcolor | \
			// 						alignleft aligncenter alignright alignjustify | \
			// 						bullist numlist outdent indent | removeformat | help"
			// 			}}
			// 			value={value}
			// 			onEditorChange={handleTextFieldChange}
			// 		/>
			// 	</Input.Wrapper>
			// );
			// let darkEditor = (
			// 	<Input.Wrapper label={label}>
			// 		<Editor
			// 			id={darkEditorTiming}
			// 			key={darkEditorTiming}
			// 			disabled={disabled}
			// 			onInit={(evt, editor) => (editorRef.current = editor)}
			// 			init={{
			// 				skin: "oxide-dark",
			// 				content_css: "dark",
			// 				height: "50vh",
			// 				width: "100%",
			// 				strict_loading_mode: true,
			// 				statusbar: false,
			// 				branding: false,
			// 				placeholder: placeholder,
			// 				fontsize_formats: "8px 10px 12px 14px 18px 24px 36px",
			// 				menubar: "file edit insert view format table",
			// 				plugins: [
			// 					"charmap advlist autolink lists link image charmap print preview anchor",
			// 					"searchreplace visualblocks code fullscreen",
			// 					"insertdatetime media table paste code help wordcount code"
			// 				],
			// 				formatpainter_blacklisted_formats:
			// 					"link,address,removeformat,formatpainter_removeformat",
			// 				toolbar:
			// 					// eslint-disable-next-line
			// 					"charmap | undo redo | formatselect | bold italic backcolor | \
			// 						alignleft aligncenter alignright alignjustify | \
			// 						bullist numlist outdent indent | removeformat | help"
			// 			}}
			// 			value={value}
			// 			onEditorChange={handleTextFieldChange}
			// 		/>
			// 	</Input.Wrapper>
			// );
			// if (colorScheme === "light" && lastTheme === "light") return lightEdotor;
			// else if (colorScheme === "dark" && lastTheme === "dark") return darkEditor;
			// else if (colorScheme === "light" && lastTheme === "dark") {
			// 	lastTheme = colorScheme;
			// 	return lightEdotor;
			// } else if (colorScheme === "dark" && lastTheme === "light") {
			// 	lastTheme = colorScheme;
			// 	return darkEditor;
			// } else if (!lastTheme) {
			// 	lastTheme = colorScheme;
			// 	if (colorScheme === "light") return lightEdotor;
			// 	else return darkEditor;
			// }
		} else if (usageType === "optionList") {
			if (optionListValue && Array.isArray(optionListValue)) {
				const normalizedValue = Array.isArray(value)
					? value[0]?._id
					: typeof value === "object" && value?._id
						? value._id
						: value;

				const normalizedOptionList = optionListValue
					.filter((item) => item && (item._id || item.value || item.name)) // Ensure valid items
					.map((item) => {
						const label = (
							item.label?.[lang] ||
							item.label ||
							item.name?.[lang] ||
							item.title?.[lang] ||
							item.name ||
							item.title ||
							T("nothingFound", lang)
						).toString();
						const value = item._id || item.value || label;
						return { label, value };
					});

				const isValidValue = normalizedOptionList.some((item) => item.value === normalizedValue);

				if (!isValidValue) {
					console.warn("🚀 ~ Value not found in optionListValue:", normalizedValue);
				}

				return (
					<Input.Wrapper label={label}>
						<Select
							placeholder={placeholder}
							disabled={disabled}
							searchable={searchable}
							clearable={clearable}
							allowDeselect={false}
							data={normalizedOptionList}
							value={normalizedValue}
							onChange={setter}
							nothingFoundMessage={T("nothingFound", lang)}
							defaultValue={defaultValue}
						/>
					</Input.Wrapper>
				);
			}
			return null;
		} else if (usageType === "switch") {
			return (
				<Flex direction={"column"} align={"center"}>
					<MantineText>{label}</MantineText>
					<Switch
						checked={value}
						mt={10}
						value={value}
						onChange={() => {
							setter(!value);
						}}
					/>
				</Flex>
			);
		} else if (usageType === "datePicker") {
			let localValue;
			if (value) {
				localValue = JSON.parse(JSON.stringify(value));
				localValue = new Date(localValue);
			} else {
				localValue = null;
			}
			return (
				<DateInput
					value={localValue}
					onChange={setter}
					valueFormat="DD/MM/YYYY"
					label={label}
					clearable
					placeholder={placeholder}
					leftSection={<IconCalendar size={16} />}
					disabled={disabled}
				/>
			);
		} else if (usageType === "number") {
			return (
				<NumberInput
					label={label}
					description={description}
					placeholder={placeholder}
					value={value || 0}
					onChange={(val) => setter(val)}
					disabled={disabled}
				/>
			);
		} else if (usageType === "url") {
			return (
				<Input.Wrapper label={label}>
					<Input
						placeholder={placeholder}
						onChange={handleChange}
						value={value || ""}
						leftSection={<IconWorldWww size={16} />}
						component={IMaskInput}
						mask="https://www.***************************************************"
						disabled={disabled}
					/>
				</Input.Wrapper>
			);
		} else if (usageType === "boolean") {
			return (
				<div key={id} className="form-element">
					<label htmlFor={label}>{label}</label>
					{value ? (
						<p>
							<i className="fas fa-thumbs-up"></i>
						</p>
					) : (
						<p>
							<i className="fas fa-exclamation-circle"></i>
						</p>
					)}
				</div>
			);
		} else if (usageType === "color-picker") {
			return (
				<div key={id} className="form-element">
					<label htmlFor={label}>{label}</label>
					<div className="input-group">
						<div className="input-group-btn">
							<button className="btn btn-info btn-flat" onClick={toggleShowColorPicker}>
								Color Picker
							</button>
						</div>
						{/* <p >color test</p> */}
						<input
							value={value || ""}
							onChange={handleChange}
							type={type}
							className="form-control"
							id={label}
							placeholder={placeholder}
						/>
						<div className="input-group-btn">
							<button className="btn btn-flat" style={{ backgroundColor: value }}>
								Color Example
							</button>
						</div>
					</div>
					{showColorPicker && <ChromePicker onChange={handleColorChange} />}
				</div>
			);
		} else if (usageType === "radio") {
			return (
				<Input.Wrapper label={label} key={new Date().getTime()}>
					<Radio.Group name={label} onChange={setter}>
						<Group mt="xs">
							{radioValue && radioValue.length > 0
								? radioValue.map((val, index) => {
										return (
											<Radio
												key={index}
												value={val.value}
												label={val.label}
												checked={val.value === value}
											/>
										);
									})
								: null}
						</Group>
					</Radio.Group>
				</Input.Wrapper>
			);
		} else if (usageType === "checkbox") {
			return (
				<Input.Wrapper label={label}>
					<Group mt="xs">
						<Checkbox
							checked={value}
							onChange={(e) => {
								setter(e.target.checked);
							}}
						/>
					</Group>
				</Input.Wrapper>
			);
		} else {
			return <h3 key={id}>There is no content to show in input</h3>;
		}
	} else {
		return (
			<Input.Wrapper label={label}>
				<Input
					placeholder={placeholder}
					value={
						usageType === "password"
							? "********"
							: usageType === "textarea"
								? parse(value ? value : "")
								: usageType === "idCard"
									? value || ""
									: usageType === "bank"
										? value || ""
										: usageType === "email"
											? value || ""
											: usageType === "phone"
												? "+374" + value || ""
												: usageType === "textarea-simple"
													? parse(value ? value : "")
													: usageType === "datePicker"
														? value
															? dayjs(value).format("DD/MM/YYYY")
															: ""
														: usageType === "number"
															? value || ""
															: usageType === "url"
																? value || ""
																: usageType === "textfield"
																	? parse(value ? value : "")
																	: usageType === "optionList"
																		? value?.name?.[lang] ||
																			value?.label?.[lang] ||
																			value?.label ||
																			value?.name ||
																			value
																			? optionListValue.filter(
																					(item) => item.value === value || item._id === value
																				)[0]?.name?.[lang] ||
																				optionListValue.filter(
																					(item) => item.value === value || item._id === value
																				)[0]?.name ||
																				optionListValue.filter(
																					(item) => item.value === value || item._id === value
																				)[0]?.label?.[lang] ||
																				optionListValue.filter(
																					(item) => item.value === value || item._id === value
																				)[0]?.label ||
																				T("nothingFound", lang)
																			: value || T("nothingFound", lang)
																		: ""
					}
					disabled={true}
					leftSection={leftIcon ? leftIcon : null}
					rightSection={rightIcon ? rightIcon : null}
				/>
			</Input.Wrapper>
		);
	}
};

export default CustomInput;
