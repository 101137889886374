import React, { useState, useEffect } from "react";
import StepPage from "../StepPage";
import * as API from "../../../helpers/api";
import config from "../../../config";
// import getUploads from "../../../helpers/getUploads";
import { useUser } from "../../../helpers/userContext";
import moment from "moment";
import { IconCalendarClock, IconCalendarWeek } from "@tabler/icons-react";
import { IconTimeline } from "@tabler/icons-react";
import LoadingSkeleton from "./loadingSceleton";
import { T } from "../../../helpers/translator";

export default function Step({
	route,
	id,
	ID,
	setID,
	modelSendToServer,
	destiny,
	lang,
	history,
	step,
	UpdateStep,
	steps,
	setStep,
	localSteps,
	activeStep,
	setActiveStep,
	isBusy,
	setBusy,
	priority,
	setPriority,
	projectType,
	setProjectType,
	priorities,
	projectTypes,
	setProjectTypes,
	projectStartDate,
	setProjectStartDate,
	handleStepChange,
	projectEndDate,
	setProjectEndDate,
	voltage,
	branch,
	city,
	village,
	community,
	permissions,
	openedInner
}) {
	const [isComponentBusy, setComponentBusy] = useState(true);
	// queue is set automatically when sending to server
	const { user } = useUser();
	const [History, setHistory] = useState([{}]);
	const [responsible, setResponsible] = useState([]);
	const [selectedTimeInterval, setSelectedTimeInterval] = useState({});
	const [timeLineSelected, setTimeLineSelected] = useState(1);

	//step specific variables
	const [index, setIndex] = useState(0);
	const [startDate, setStartDate] = useState([]);
	const [endDate, setEndDate] = useState([]);
	const [deadline, setDeadline] = useState("");
	const [Id, setCurrentStepId] = useState("");
	const [currentSTEP, setCurrentSTEP] = useState({});

	// inputs variables
	const [descEn, setDescEn] = useState("");
	const [descRu, setDescRu] = useState("");
	const [descHy, setDescHy] = useState("");
	// uploads
	const [pdf, setPdf] = useState([]);
	const [images, setImages] = useState([]);
	const [historySteps, setHistorySteps] = useState([]);

	const setUploadsByTimeInterval = async (item) => {
		if (item.destiny === "pdf") {
			if (
				moment(item.date) > moment(selectedTimeInterval.startDate) &&
				moment(item.date) < moment(selectedTimeInterval.endDate)
			) {
				setPdf((prev) => [...prev, item]);
			}
		}
		if (item.destiny === "images") {
			if (
				moment(item.date) > moment(selectedTimeInterval.startDate) &&
				moment(item.date) < moment(selectedTimeInterval.endDate)
			) {
				setImages((prev) => [...prev, item]);
			}
		}
		return item;
	};

	let otherFields = {
		desc: { en: descEn, ru: descRu, hy: descHy }
	};

	useEffect(() => {
		(async () => {
			try {
				let currentStep = await API.get({
					route: config.api.step,
					query: {
						project_id: id,
						queue: step
					},
					uploadType: true,
					limit: 1
				});

				// set responsible from voltage
				if (voltage?.steps?.find((item) => item.index === step)?.responsible.length > 0) {
					let responsible = voltage?.steps
						?.find((item) => item.index === step)
						?.responsible.map(async (resp) => {
							let uploads = await API.get({
								route: config.api.upload,
								query: {
									obj_id: resp._id
								}
							});
							return { ...resp, uploads };
						});
					Promise.all(responsible).then(setResponsible);
				} else {
					setResponsible([]);
				}
				// set all states from server
				currentStep[0] && setCurrentSTEP(currentStep[0]);
				currentStep[0]?._id && setCurrentStepId(currentStep[0]?._id);
				currentStep[0]?.startDate && setStartDate(currentStep[0]?.startDate);
				currentStep[0]?.endDate && setEndDate(currentStep[0]?.endDate);
				currentStep[0]?.history && setHistory(currentStep[0]?.history);
				currentStep[0]?.desc?.en && setDescEn(currentStep[0]?.desc.en);
				currentStep[0]?.desc?.ru && setDescRu(currentStep[0]?.desc.ru);
				currentStep[0]?.desc?.hy && setDescHy(currentStep[0]?.desc.hy);

				let historySteps = [];
				if (currentStep[0]?.history) {
					for (let i = 0; i < currentStep[0]?.history.length; i++) {
						if (currentStep[0]?.history[i].status === "inProgress") {
							// historySteps.push(currentStep[0]?.history[i]);
							historySteps.push({
								startDate: currentStep[0]?.history?.[i].date,
								endDate: currentStep[0]?.history?.[i + 1]?.date,
								changes: {}
							});
						}
					}
				}

				setHistorySteps(historySteps);
				setTimeLineSelected(historySteps.length - 1);
				setSelectedTimeInterval({
					startDate: historySteps[historySteps.length - 1]?.startDate,
					endDate: historySteps[historySteps.length - 1]?.endDate
				});

				setPdf([]);
				setImages([]);
				currentStep[0]?.uploads?.forEach((item) => {
					setUploadsByTimeInterval(item);
				});

				setDeadline(voltage?.deadline?.step5);
			} catch (error) {
				console.error(error);
			}

			// eslint-disable-next-line
			setBusy(false);
			setComponentBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy, isComponentBusy]);

	useEffect(() => {
		// change default files on selectedTimeInterval change
		setPdf([]);
		setImages([]);
		currentSTEP?.uploads?.forEach((item) => {
			setUploadsByTimeInterval(item);
		});
		// eslint-disable-next-line
	}, [selectedTimeInterval]);

	if (isComponentBusy) return <LoadingSkeleton />;
	return (
		<StepPage
			isBusy={isBusy}
			setBusy={setBusy}
			route={route}
			projectId={id}
			stepId={Id}
			handleStepChange={handleStepChange}
			lang={lang}
			History={History}
			modelSendToServer={modelSendToServer}
			pageTitle={localSteps?.[step - 1]?.name[lang]}
			step={step}
			UpdateStep={UpdateStep}
			activeStep={activeStep}
			setActiveStep={setActiveStep}
			steps={steps}
			setStep={setStep}
			localSteps={localSteps}
			startDate={startDate}
			endDate={endDate}
			selectedTimeInterval={selectedTimeInterval}
			setSelectedTimeInterval={setSelectedTimeInterval}
			timeLineSelected={timeLineSelected}
			setTimeLineSelected={setTimeLineSelected}
			historySteps={historySteps}
			setComponentBusy={setComponentBusy}
			permissions={permissions}
			openedInner={openedInner}
			responsible={responsible}
			isComponentBusy={isComponentBusy}
			otherFields={otherFields}
			inputs={[
				historySteps[0]?.startDate && {
					value: moment(historySteps[0]?.startDate).format("DD-MM-YYYY, HH:mm:ss"),
					type: "text",
					usageType: "textfield",
					label: T("startDate", lang),
					disabled: true,
					permissionModel: "step5-startDate",
					rightIcon: <IconTimeline />
				},
				historySteps[0]?.startDate && historySteps[historySteps.length - 1]?.endDate
					? {
							value:
								"It lasts " +
								moment(historySteps[historySteps.length - 1]?.endDate).diff(
									historySteps[historySteps.length - 1]?.startDate,
									"minutes"
								) +
								" working minutes",
							type: "text",
							usageType: "textfield",
							label: T("processTime", lang),
							permissionModel: "step5-processTime",
							disabled: true,
							rightIcon: <IconCalendarClock />
						}
					: {
							value:
								"Still going on " +
								moment(new Date()).diff(
									historySteps[historySteps.length - 1]?.startDate,
									"minutes"
								) +
								" working minutes",
							type: "text",
							usageType: "textfield",
							label: T("processTime", lang),
							permissionModel: "step5-processTime",
							disabled: true,
							rightIcon: <IconCalendarClock />
						},
				historySteps[historySteps.length - 1]?.endDate && {
					value: moment(historySteps[historySteps.length - 1]?.endDate).format(
						"DD-MM-YYYY, HH:mm:ss"
					),
					type: "text",
					usageType: "textfield",
					label: T("endDate", lang),
					disabled: true,
					permissionModel: "step5-endDate",
					rightIcon: <IconCalendarClock />
				},
				deadline && {
					value: moment(historySteps[0]?.startDate).add(deadline, "days").format("DD-MM-YYYY"),
					type: "text",
					usageType: "textfield",
					label: T("deadline", lang),
					permissionModel: "step5-deadline",
					disabled: true,
					rightIcon: <IconCalendarWeek />
				},
				{
					value: descEn,
					setter: setDescEn,
					type: "textarea",
					usageType: "textarea",
					label: T("description", lang),
					placeholder: T("inputInLatin", lang),
					disabled: steps?.find((item) => item.queue === step)?.status === "done",
					lang: "en",
					permissionModel: "step5-desc",
					width: 12
				},
				{
					value: descRu,
					setter: setDescRu,
					type: "textarea",
					usageType: "textarea",
					label: T("description", lang),
					placeholder: T("inputInRussian", lang),
					disabled: steps?.find((item) => item.queue === step)?.status === "done",
					lang: "ru",
					permissionModel: "step5-desc",
					width: 12
				},
				{
					value: descHy,
					setter: setDescHy,
					type: "textarea",
					usageType: "textarea",
					label: T("description", lang),
					placeholder: T("inputInArmenian", lang),
					disabled: steps?.find((item) => item.queue === step)?.status === "done",
					permissionModel: "step5-desc",
					lang: "hy",
					width: 12
				}
			]}
			filesComponent={[
				{
					destiny: "images",
					files: images,
					setFiles: setImages,
					filesUploadLimit: 3,
					filesMaxSize: 5,
					acceptedFiles: ["image/png", "image/jpeg", "image/jpg"],
					adminMode: user.role !== "editor",
					height: 500,
					permissionModel: "step5-images",
					placeholder: "Նկարներ"
				},
				{
					destiny: "pdf",
					files: pdf,
					setFiles: setPdf,
					filesUploadLimit: 3,
					filesMaxSize: 5,
					acceptedFiles: [".pdf"],
					adminMode: user.role !== "editor",
					height: 500,
					permissionModel: "step5-pdf",
					placeholder: "PDFs"
				}
			]}
		/>
	);
}
