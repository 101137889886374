import React from "react";
import { Button, Drawer, Flex, Group, Image, Indicator, Paper, Text } from "@mantine/core";
import { useLang } from "../../../helpers/language";
import config from "../../../config";

import { IconPhone, IconAlertTriangle } from "@tabler/icons-react";
import { useSocket } from "../../../helpers/socket";

const UserInfo = ({ data, open, onClose }) => {
	const { lang } = useLang();
	const { connectedUsers = [] } = useSocket();
	let userConnectionStatus = connectedUsers.find(
		(user) => user._id === data?._id
	)?.connectionStatus;

	return (
		<Drawer
			opened={open}
			onClose={onClose}
			title="User Info"
			position="right"
			overlayProps={{ backgroundOpacity: 0.2, blur: 0 }}
		>
			<Paper p="xl" mb="lg" withBorder>
				<Indicator
					processing={
						userConnectionStatus ? (userConnectionStatus === "online" ? true : false) : false
					}
					color={
						userConnectionStatus
							? userConnectionStatus === "online"
								? "green"
								: userConnectionStatus === "away"
							: "orange"
					}
					size={14}
				>
					<Image radius="md" src={`${config.api.API_URL}/${data?.uploads?.[0]?.path}` || ""} />
				</Indicator>
			</Paper>
			<Group justify="start" grow>
				<Button leftSection={<IconPhone size={14} />} variant="default">
					<a href={`tel:+374${data?.phone}`}>Start call</a>
				</Button>
				<Button leftSection={<IconAlertTriangle size={14} />} variant="default">
					View reported messages
				</Button>
			</Group>
			<Flex direction="column">
				<Text mt="sm" fw={700}>
					Name
				</Text>
				<Text>{data?.name?.[lang]}</Text>
			</Flex>
			<Flex direction="column">
				<Text mt="sm" fw={700}>
					Username
				</Text>
				<Text>{data?.username}</Text>
			</Flex>
			<Flex direction="column">
				<Text mt="sm" fw={700}>
					Email
				</Text>
				<Text td="underline">
					<a href={`mailto:${data?.email}`}>{data?.email}</a>
				</Text>
			</Flex>
			<Flex direction="column">
				<Text mt="sm" fw={700}>
					Last login
				</Text>
				<Text>18:27</Text>
			</Flex>
		</Drawer>
	);
};

export default UserInfo;
